import { Body, ProgressBar, type ProgressBarProps } from '@sumup/circuit-ui';
import type React from 'react';
import { useIntl } from 'react-intl';

import { getErrorMessage } from '../../services/forms';

import {
  PASSWORD_MIN_LENGTH_ERROR,
  STRENGTH_METER_EXTRA_INFORMATION,
  STRENGTH_METER_MESSAGES,
} from './PasswordInputService';
import styles from './styles.module.css';

const getProgressColorByStrengthLevel = (
  strength: number | undefined,
  noColor?: boolean,
) => {
  if (noColor) {
    return 'var(--cui-fg-subtle)';
  }

  switch (strength) {
    case 1:
    case 2:
      return 'var(--cui-border-danger)';
    case 3:
      return 'var(--cui-border-warning)';
    case 4:
    case 5:
      return 'var(--cui-border-success)';
    default:
      return 'var(--cui-fg-subtle)';
  }
};

interface IColoredProgressBarProps extends ProgressBarProps {
  noColor?: boolean;
}

const ColoredProgressBar = ({
  value,
  noColor,
  ...props
}: IColoredProgressBarProps) => {
  const progressBarStyle: React.CSSProperties = {
    '--progress-bar-color': getProgressColorByStrengthLevel(value, noColor),
  } as React.CSSProperties;

  return (
    <ProgressBar
      className={styles.coloredProgressBar}
      style={progressBarStyle}
      value={value}
      {...props}
    />
  );
};

interface IColoredBodyProps {
  score: number;
  noColor?: boolean;
  children: React.ReactNode;
}

const ColoredBody = ({ score, noColor, children }: IColoredBodyProps) => {
  const style = {
    color: getProgressColorByStrengthLevel(score, noColor),
  };

  return (
    <span
      className={score > 0 ? styles.coloredBodyBold : styles.coloredBody}
      style={style}
    >
      {children}
    </span>
  );
};

const MAX_PROGRESS = 5;

export interface StrengthMeterProps {
  score: number;
  passwordError?: string;
}

export const StrengthMeter = ({ score, passwordError }: StrengthMeterProps) => {
  const intl = useIntl();

  const isPasswordTooShort = passwordError === PASSWORD_MIN_LENGTH_ERROR;
  const validationHint = getErrorMessage(
    STRENGTH_METER_MESSAGES,
    score.toString(),
  );
  const extraInformation = getErrorMessage(
    STRENGTH_METER_EXTRA_INFORMATION,
    score.toString(),
  );

  return (
    <div className={styles.strengthMeterContainer}>
      <ColoredProgressBar
        data-testid="password-strength-meter"
        size="byte"
        max={MAX_PROGRESS}
        value={isPasswordTooShort ? 1 : score}
        noColor={isPasswordTooShort}
        hideLabel
        aria-hidden="true"
        label="-"
      />
      {validationHint && (
        <Body
          size="two"
          id="password-input-description"
          aria-live="polite"
          aria-atomic="true"
        >
          <ColoredBody score={score} noColor={isPasswordTooShort}>
            {`${intl.formatMessage(validationHint)} `}
          </ColoredBody>
          {extraInformation && intl.formatMessage(extraInformation)}
        </Body>
      )}
    </div>
  );
};
